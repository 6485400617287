@import 'assets/styles/colours.scss';

.contact {
  padding-bottom: 50px;
  color: $dark-color;

  a {
    color: $dark-color;
  }

  :global(.col--auto) {
    margin-right: 22px;
    margin-top: 22px;
    max-width: 350px;
  }

  :global(.store-locator) {
    width: 100%;
    margin-bottom: 150px;
  }
  
  &__heading {
    font-size: 63px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.location {
  padding-top: 0;
}

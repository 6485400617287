@import 'assets/styles/colours.scss';

.recipes__title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  :global(.button-group) {
    margin: 0;
    display: flex;
    flex-direction: row;

    button {
      margin-left: 20px;
      border: $primary-color 1px solid;
      background: $primary-color;

      &:first-of-type {
        background: transparent;
      }
    }
  }
}

.recipes__heading {
  color: $dark-color;
  margin: 0;
}

.recipes__list {
  white-space: nowrap;
  transition: margin 0.8s ease-out;
}

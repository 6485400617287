@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';

:global(.store-locator) {
  background: #FFF;
  box-shadow: 0px 0px 10px 2px rgba(175 182 170 / 40%);
  border-radius: 20px;
  padding: 85px 100px;
  display: flex;
  box-sizing: border-box;
  font-size: 22px;
  width: 80%;
  max-width: 1200px;

  &::before {
    content: '';
    position: absolute;
    left: 35px;
    top: -5px;
    width: 47px;
    height: 84px;
    background: url('../../assets/images/icons/location.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.store-locator__heading {
  color: $dark-color;
  font-weight: 600;
  font-size: 42px;
  margin-top: 0;
  margin-bottom: 25px;
}

.store-locator__subheading {
  color: $primary-color;
  font-size: 30px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 40px;
}

.store-locator__col-divider {
  margin-left: 10%;
  margin-right: 10%;
  height: 250px;
}

.store-locator__link {
  margin-top: 50px;
  display: block;
}

.store-locator__link {
  margin-top: 50px;
  display: block;
}

/* Media Queries */
@media (max-width: 1500px) {
  .store-locator__heading {
    font-size: 28px;
  }
}

@media (max-width: $breakpoint-xl) {
  .store-locator {
    padding: 60px 80px;

    &::before {
      height: 70px;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  :global(.store-locator) {
    padding: 40px 50px;
    flex-wrap: wrap;
    justify-content: center;

    &::before {
      height: 50px;
      left: 25px;
    }

    & > :global(.col--auto) {
      flex: unset;

      &:last-child {
        margin-top: 60px;
      }
    }
  }

  .store-locator__heading {
    font-size: 36px;
  }

  .store-locator__col-divider {
    display: none;
  }
}

@media (max-width: $breakpoint-sm) {
  :global(.store-locator) {
    width: calc(100% - 30px);
    padding: 60px 25px;
  }
}

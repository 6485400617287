@import 'assets/styles/sizes.scss';

section {
  padding: 100px 10%;

  .section__content {
    max-width: 1200px;
    margin: auto;
  }
}

@media (max-width: $breakpoint-md) {
  section {
    padding: 50px 15px;
  }
}

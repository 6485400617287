@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';

header {
  padding: 0 10%;
  background: url('../../assets/images/background-tex-04.png') $extra-light-color;
  background-size: cover;
  background-position: center right;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 40%;
    height: 100vh;
    right: 0;
    background-position: top right;
    pointer-events: none;
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 1000px;
  }

  &::before {
    background-image: url('../../assets/images/cooking-banner.png');
    top: 80px;
  }

  &::after {
    background-image: url('../../assets/images/clouds-01.png');
    mix-blend-mode: screen;
    max-width: 1000px;
    animation: pulse 8s infinite linear;
    top: 50px;
  }

  & :global(.button--small) {
    position: absolute;
    bottom: -20px;
    transform: rotate(90deg);
  }
}

.nav {
  display: flex;
  position: absolute;
  top: 45px;
}

.nav__image {
  width: 150px;
}

.nav__list {
  display: flex;
  align-items: center;
  margin-left: 5vw;
}

.nav__list-item {
  display: inline;
  font-size: 18px;
  font-weight: 700;
  padding: 0 10px;

  a {
    text-decoration: none;
    color: $dark-color;
    text-transform: uppercase;
  }
}

.header-container {
  margin-right: 45%;
  height: 95vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header-container__heading {
  font-size: 63px;
  font-weight: 400;
  color: $dark-color;
  margin: 0;
  line-height: 1.2;
}

.header-container__subheading {
  color: $primary-color;
  font-weight: 600;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

/* Media Queries */
@media (max-width: 1600px) {
  .header-container__heading {
    font-size: 46px;
  }

  .header-container__subheading {
    margin-top: 40px;
  }

  .nav__image {
    width: 100px;
  }
}

@media (max-width: $breakpoint-lg) {
  header {
    background: url('../../assets/images/background-tex-01.png') $extra-light-color;
    background-position: bottom;

    &::before,
    &::after {
      content: none;
    }
  }

  .header-container {
    margin: 0;
  }
}

@media (max-width: $breakpoint-md) {
  .nav__image {
    display: none;
  }

  .nav__list {
    margin-left: 0;
  }

  .nav {
    left: 0;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    justify-content: center;
  }
}

@media (max-width: $breakpoint-sm) {
  .nav__list-item {
    font-size: 12px;
  }

  .header-container__subheading {
    margin-top: 0;
  }
}

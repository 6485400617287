@import 'assets/styles/colours';
@import 'assets/styles/sizes';

.store-locator {
  position: relative;
  border-radius: 24px;
  overflow: hidden;

  &-results__name {
    margin: 0;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
  }

  &__error {
    margin-top: 20px;
    top: 100%;
    position: absolute;
    width: 100%;
    text-align: center;
  }

  &__sub-title {
    padding: 10px 0 20px 0;
    color: $light-color;
    text-align: center;
    border-bottom: 1px solid #F2F2F2;
  }

  &__map {
    height: 600px;
    max-width: 50%;
    margin-left: auto;

    :global(.gm-control-active > img) { 
      left: 13.5%;
    }
  }

  &__results {
    padding-right: 30px;

    li {
      padding: 15px 0;
      border-bottom: 1px solid #f2f2f2;

      button {
        position: relative;
        padding: 0;
        border: none;
        background: none;
        font-size: 14px;
        text-transform: initial;
        color: #439250;

        &:hover {
          background: none;
        }

        &::after {
          content: '';
          position: absolute;
          top: 4px;
          right: 12px;
          width: 7px;
          height: 7px;
          border-top: 2px solid #439250;
          border-right: 2px solid #439250;
          transform: rotate(45deg);
        }
      }
            
      p {
        margin-bottom: 0;
      }
    }
  }

  &__result-item {
    width: 100%;
    padding: 20px 25px;
    padding-left: 0;
    text-align: left;
    border: none;
    border-bottom: 1px solid #F4F4F4;
  }
}

.store-locator-form {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 50%;
  overflow-y: scroll;

  &__header {
    padding-right: 30px;
  }

  &__input-group {
    border: 1px solid $primary-color;
    background: $extra-light-color;
    border-radius: 100px;
    padding: 3px;
    position: relative;
    display: flex;
  }

  &__input {
    border: none !important;
    padding: 0px 20px;
    width: calc(100% - 50px);
    background: none;
    box-sizing: border-box;
    height: 50px;
    outline: none;
    font-size: 16px;
  }

  &__button {
    background: $primary-color;
    color: white;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    vertical-align: middle;
    padding: 0;
    min-width: unset;;
    display: flex;

    img {
      margin: auto;
    }
  }

  &__button-small {
    border: none;
    text-transform: inherit;
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 14px;
  
    &:hover {
      background: none;
      color: initial;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .store-locator {
    &__map {
      height: 600px;
      max-width: 100%;
      margin-left: auto;
    }

    &-form {
      position: static;
      height: 400px;
      width: 100%;
    }
  }
}
@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';

.content-container {
  display: flex;
  justify-content: center;
  padding: 50px 10%;
}

.content-container__inner {
  width: 100%;
  max-width: 1200px;
}

.back-button {
  box-shadow: 0px 0px 10px 2px rgba(175 182 170 / 40%);
  border-radius: 60px;
  background: #FFF;
  padding: 20px 25px;
  display: block;
  width: fit-content;
  text-transform: uppercase;
  text-decoration: none;
  color: $primary-color;
  margin-bottom: 100px;

  img {
    width: 20px;
    vertical-align: middle;
    margin-right: 10px;
  }
}

.card {
  background: #FFF;
  box-shadow: 0px 0px 10px 2px rgba(175 182 170 / 40%);
  border-radius: 20px;
  padding: 0;

  p {
    font-size: 28px;
    font-weight: 100;
  }

  &__header {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 600;
    color: $dark-color;
    font-family: museo-sans, sans-serif;
  }

  &__container {
    display: flex;
    padding: 60px;
    border-bottom: 1px solid #D4D2C4;
    flex-wrap: wrap;
  
    &:first-of-type {
      justify-content: center;
    }
  
    &:last-of-type {
      border-bottom: none;
      padding: 40px 60px;
    }
  }

  &__column {
    margin-right: 22px;
    width: calc(50% - 22px);
  }
  
  &__feature-image {
    width: 50%;
    height: 400px;
    border-radius: 20px;
    align-self: start;
    background-size: cover;
    background-position: center;
  }

  &__element {
    font-size: 16px;
    font-weight: bold;
    padding: 8px 16px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    font-family: museo-sans, sans-serif;
  
    &:first-of-type {
      background: $primary-color;
      border: none;
      color: #FFF;
    }
  
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  
    img,
    svg {
      margin-right: 5px;
      vertical-align: middle;
    }
  }
}

.details {
  padding: 100px 10px 50px;
  display: flex;
  font-size: 22px;
  color: $dark-color;

  &:first-child {
    margin-right: 50px;
  }

  &__container {
    display: flex;
    gap: 60px;
  }

  &__ingredients {
    li {
      border-bottom: 1px solid #D5D2C5;
      padding: 20px 25px;
      padding-left: 0;
  
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

/* Media Queries */
@media (max-width: $breakpoint-xl) {
  .card {  
    p {
      font-size: 22px;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .card {  
    &__column {
      width: 100%;
      margin-right: 0;
    }

    &__feature-image {
      width: 80%;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .content-container {
    padding: 50px 15px;
  }
}

@media (max-width: $breakpoint-md) {
  .card__feature-image {
    width: 100%;
    height: 300px;
  }

  .card__container {
    padding: 20px;

    &:last-of-type {
      padding: 30px 20px;
    }
  }

  .details {
    padding: 50px 0;

    .details__container {
      display: block;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .card {  
    p {
      font-size: 18px;
    }

    &__header {
      font-size: 22px;
    }
  }

  .details {
    font-size: 18px;

    li {
      padding: 15px 0;
    }
  }
}

@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';

.recipe-card {
  width: 70%;
  max-width: 260px;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  display: inline-block;
  margin: 0 22px 22px 0;
  border: 1px solid #D5D2C5;
  transition: 0.2s ease-in-out all;

  a {
    color: $dark-color;
    text-decoration: none;
  }

  &:hover {
    box-shadow: 0px 0px 10px 2px rgba(175 182 170 / 40%);
  }
}

.recipe-card__container {
  padding: 15px;
  white-space: normal;
}

.recipe-card__title {
  margin: 0;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 14px;
  font-family: museo-sans, sans-serif;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.recipe-card__image {
  display: block;
  width: 100%;
  height: 210px;
  background-size: cover;
  background-position: center;
}

.recipe-card__elem {
  font-size: 12px;
  font-weight: bold;
  border: 1px solid rgba(0 0 0 / 20%);
  padding: 3px 8px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  width: fit-content;
  font-family: museo-sans, sans-serif;

  &:first-of-type {
    border: 1px solid transparent;
    color: $dark-color;
  }

  &:not(:first-of-type) {
    margin-left: 6px;

    &:hover {
      background: rgba(0 0 0 / 10%);
    }
  }

  img,
  svg {
    margin-right: 5px;
    vertical-align: middle;
  }
}

@media (max-width: $breakpoint-md) {
  .recipe-card {
    width: 100%;
    margin-right: 10px;
  }
}

@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';

.header__image {
  justify-content: center;
  padding-left: 50px;
  box-sizing: border-box;
  height: 600px;
}

.recipe {
  position: relative;
  font-size: 28px;
  color: $extra-light-color;
  padding: 150px 10% 150px 0;
  margin-top: 250px;
  background: $dark-color;
  border-radius: 0 40px 40px 0;
  margin-right: 10%;
  max-width: 1700px;
  box-sizing: border-box;

  :global(.button--light:hover) {
    background: $light-color;
    border-color: $light-color;
    color: $primary-color;
  }
}

.recipe__head {
  color: $primary-color;
  font-size: 28px;
}

.recipe__subhead {
  font-size: 48px;
  margin: 0;
}

.recipe__image {
  position: absolute;
  left: -40px;
  width: 45%;
  transform: translateY(-50%) scaleX(-1);
  top: 50%;
  max-width: 900px;
}

.locator {
  height: 300px;
  position: relative;
  background: #CCD3C3;
  background-image: url('../../assets/images/horizontal-decoration-02.png');
  background-repeat: no-repeat;
}

.related {
  background: $extra-light-color;
}

.related__head {
  color: $dark-color;
}

.related__related-items {
  display: flex;
  margin-bottom: 100px;
  flex-wrap: wrap;

  li {
    width: 30%;
    max-width: 300px;
    box-sizing: border-box;
    background: white;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 15px;
    margin-bottom: 20px;
    border: 1px solid #D5D2C5;
    box-shadow: 0px 0px 10px 2px rgba(175 182 170 / 40%);
    display: flex;
    flex-direction: column;

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

.related-item__link {
  img {
    padding: 30px;
    display: block;
    max-height: 200px;
    box-sizing: border-box;
    margin: auto;
  }
}

.related-item__content {
  padding: 20px;
  border-top: 1px solid #D4D2C4;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  text-align: center;
  font-family: museo-sans, sans-serif;
}

.related-item__nutrition {
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  margin-top: 20px;
  text-transform: none;
  color: $primary-color !important;
  background: none !important;

  img {
    margin-right: 5px;
  }
}

/* Media Queries */
@media (max-width: $breakpoint-lg) {
  .header {
    :global(.halfWidth) {
      width: 100%;
    }
  }

  .header__image {
    margin-top: 80px;
  }

  .recipe {
    border-radius: 0;
    width: 100%;
    margin-left: 0;
    padding: 150px 10%;

    :global(.halfWidth) {
      width: 100%;
    }
  }

  .recipe__image {
    display: none;
  }
}

@media (max-width: $breakpoint-md) {
  .related__related-items {
    li {
      width: calc(50% - 7.5px);
  
      &:nth-of-type(3n) {
        margin-right: 15px;
      }

      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }

  .related-item__content {
    font-size: 16px;
  }

  .header__image {
    max-height: 300px;
    padding: 0;
    margin: 0;
    order: -1;

    img {
      height: 100%;
    }
  }

  .locator {
    height: 550px;
    margin-top: 100px;
    padding: 30px 15px;
  }
}

@media (max-width: 450px) {
  .related__related-items {
    li {
      width: 100%;
      max-width: none;
      margin-right: 0;
    }
  }
}
@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';

.header__image {
  justify-content: center;
  position: relative;

  img {
    position: absolute;
    left: 25%;
    border-radius: 30px;
  }
}

.health {
  background: $light-color;
  color: $dark-color;
  text-align: center;
}

.wholesalers {
  padding-top: 0px;
}

/* Media Queries */
@media (max-width: $breakpoint-lg) {
  .header {
    :global(.halfWidth) {
      width: 100%;
    }
  }

  .header__image {
    margin-top: 80px;

    img {
      position: relative;
      left: unset;
      border-radius: 30px;
    }
  }

  .recipe {
    border-radius: 0;
    width: 100%;
    margin-left: 0;
    padding: 150px 10%;

    :global(.halfWidth) {
      width: 100%;
    }
  }

  .recipe__image {
    display: none;
  }
}

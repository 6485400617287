@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';

.products-list__item {
  display: flex;
  flex-wrap: wrap;
}

.products-list__number,
.products-list__name,
.products-list__quantity,
.products-list__delete,
.products-list__add {
  margin-right: 30px;
  margin-bottom: 30px;
  max-width: 100%;
}

.products-list__number {
  font-size: 40px;
}

.products-list__name {
  flex: 1;
}

.products-list__delete {
  background: transparent !important;
  border: none;
  min-width: unset;
  margin-right: 0;
  padding-left: 0;
}

.products-list__add {
  background: transparent !important;
  border: none;
  font-size: 18px;
  color: $primary-color;
  margin-left: 22px;

  &:hover {
    color: $dark-color;
  }
}

.company-details__field {
  width: calc(50% - 30px);
  margin-right: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
}

.wholesaler__heading {
  color: $dark-color;
}

.wholesaler__submit {
  margin-top: 50px;
}

select {
  appearance: none;
}

input,
select {
  font-size: 18px;
  padding: 25px 35px;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  background: #FFF;
  outline: none;

  &:focus,
  &:active {
    border: 1px solid $primary-color;
  }

  &:global(.error) {
    border: 1px solid red;
  }
}

@media (max-width: $breakpoint-md) {
  select,
  input {
    height: 60px;
    padding: 15px;
    box-sizing: border-box;
  }

  .products-list__number,
  .products-list__name,
  .products-list__quantity,
  .products-list__delete,
  .products-list__add {
    margin-bottom: 10px;
  }

  .company-details__field {
    width: 100%;
    padding: 15px;
  }
}

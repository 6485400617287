@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';

.categories {
  width: 25%;

  li {
    border-bottom: 1px solid $light-color;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__heading,
  &__button {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }

  &__heading {
    color: #F4F1DE;
    background: $dark-color;
    margin: 0;
    padding: 15px 25px;
    border-radius: 20px 20px 0px 0px;
  }
  
  &__button {
    padding: 24px 18px;
    width: 100%;
    height: 100%;
    border: none;
    position: relative;
    background: #FFF;
    transition: all 0.2s ease-in-out;
    border-radius: unset;
    color: $dark-color;
  
    &.categories__button--selected {
      color: $primary-color;
      background: $light-color;
  
      &::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 25px;
        right: 10px;
        background: url('../../assets/images/icons/chevron-right.svg') no-repeat;
      }
    }
  }
}

.recipes-list,
.error-container {
  width: 75%;
  padding-left: 22px;
  box-sizing: border-box;
}

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-text {
  font-size: 18px;
  color: $dark-color;
  font-weight: 600;
}

/* Media Queries */
@media (max-width: $breakpoint-lg) {
  .categories {
    flex: unset;
    width: 100%;
  }

  .recipes-list,
  .error-container {
    width: 100%;
    padding-left: 0;
    margin-top: 50px;
    text-align: center;
  }
}

@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';

nav {
  position: relative;
  z-index: 99;
}

.nav__list {
  display: flex;
  background: #FFF;
  height: 70px;

  li {
    flex: 1;
    border-right: 1px solid #D5D2C5;
    border-bottom: 1px solid #D5D2C5;
    justify-content: center;
    align-items: center;
    display: flex;

    &:last-of-type,
    &:nth-of-type(3) {
      border-right: none;
    }

    a {
      text-transform: uppercase;
      text-decoration: none;
      font-size: 18px;
      font-weight: 700;
      color: $dark-color;

      &:hover,
      &:global(.active) {
        color: $primary-color;
      }
    }
  }
}

.nav__image {
  padding: 10px 20px;
  flex: unset !important;
  background: $primary-color;
  border: none !important;
  border-radius: 0 0 5px 5px;
  height: 100%;

  img {
    width: 100px;
  }
}

.nav__button {
  display: none;
  position: fixed;
  right: 20px;
  top: 0;
  z-index: 10;
  border: 0;
  margin: 0;
  min-width: 0;
  background: #FFF !important;
  padding: 15px 15px 10px;
  border-radius: 0 0 10px 10px;
}

.nav__button-container {
  width: 20px;
  height: 20px;
  position: relative;
}

.nav__button span,
.nav__button span::before,
.nav__button span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: $dark-color;
  transition-duration: 0.25s;
  bottom: 50%;
}

.nav__button span::before {
  content: '';
  top: -8px;
}

.nav__button span::after {
  content: '';
  top: 8px;
}

.nav__button--opened span {
  transform: rotate(45deg);
}

.nav__button--opened span::before {
  top: 0;
  transform: rotate(0deg);
}

.nav__button--opened span::after {
  top: 0;
  transform: rotate(90deg);
}

/* Media Queries */
@media (max-width: $breakpoint-lg) {
  .nav__list {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    flex-direction: column;

    &:global(.closed) {
      display: none;
    }

    li {
      border-right: none;
      order: 2;
      max-height: 120px;
    }

    .nav__image {
      height: unset;
      order: 1;
      border-radius: 0;
    }
  }

  .nav__button {
    display: unset;

    &.nav__button--dark {
      svg {
        fill: $primary-color;
      }
    }
  }
}

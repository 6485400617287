@import 'assets/styles/sizes.scss';

.nutrition-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0 0 0 / 80%);
}

.nutrition-modal__container {
  position: absolute;
  background: white;
  border-radius: 20px;
  padding: 30px;
  max-height: 90vh;
  overflow: scroll;
  box-sizing: border-box;
  text-align: center;
}

.nutrition-modal__close {
  display: block;
  margin-left: auto;
  margin-top: 30px;
}

/* Media Queries */
@media (max-width: $breakpoint-lg) {
  .nutrition-modal__container {
    width: 100%;
    height: 100%;
    max-height: unset;
    border-radius: 0;
  }
}

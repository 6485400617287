@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';

.products-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  li {
    width: 25%;
    display: block;
    padding: 0 20px;
    margin-bottom: 100px;
    box-sizing: border-box;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    font-family: museo-sans, sans-serif;
    text-align: center;
  }
}

.categories-list {
  background: url('../../assets/images/horizontal-decoration-02.png') $light-color;
  border-radius: 100px;
  display: flex;
  margin: auto;
  padding: 20px 50px;
  padding-bottom: 0;
  width: fit-content;
  box-sizing: border-box;
  flex-wrap: wrap;

  li {
    margin: 0 10px;
    margin-bottom: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.categories-list__button {
  background: $extra-light-color;
  padding: 15px 40px;
  font-size: 16px;
  text-transform: uppercase;
  border: 0;
  border-radius: 100px;

  &:hover {
    background: $primary-color;
  }

  &.categories-list__button--selected {
    background: $dark-color;
    color: #FFF;
  }
}

.products-list__image {
  margin-bottom: 20px;
  max-height: 150px;
}

.products-list__text {
  color: $dark-color;
  font-size: 18px;
}

.products-list__nutrition {
  color: $primary-color !important;
  background: none !important;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 10px;
  text-transform: none;
  font-size: 18px;

  img {
    margin-right: 5px;
  }
}

.locator {
  height: 300px;
  position: relative;
  margin-top: 50px;
  background: #CCD3C3;
  background-image: url('../../assets/images/horizontal-decoration-02.png');
}

/* Media Queries */
@media (max-width: 1500px) {
  .products-list {
    li {
      width: 33%;
      margin-bottom: 50px;
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .categories-list {
    width: 80%;
  }
}

@media (max-width: $breakpoint-md) {
  .products-list__text {
    font-size: 16px;
  }

  .categories-list {
    display: flex;
    justify-content: center;
    width: calc(100% - 30px);
    padding: 20px 15px;
    padding-bottom: 0;
    border-radius: 20px;

    &__button {
      padding: 15px 20px;
      min-width: 125px;
    }

    li {
      margin: 0 7px;
      margin-bottom: 15px;
    }
  }

  .locator {
    height: 400px;
    margin-top: 200px;
    padding: 30px 15px;
  }
}

@media (max-width: $breakpoint-sm) {
  .locator {
    height: 550px;
    margin-top: 170px;
    padding: 30px 15px;
  }
}

@media (max-width: 650px) {
  .products-list {
    li {
      width: 50%;
    }
  }
}

@media (max-width: 500px) {
  .products-list {
    li {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}

@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';

footer {
  font-size: 16px;
  font-family: museo-sans, sans-serif;
  font-weight: 300;
}

.footer-container {
  background: #2C422C;
  color: white;
  padding: 70px 10%;
  display: flex;

  &__contact-info {
    p {
      margin: 0;
      margin-bottom: 2px;
    }
  }

  a {
    color: white;
  }
}

.footer-container__small_heading {
  color: $primary-color;
  margin-bottom: 25px;
  font-size: 18px;
  padding-top: 3px;
}

.footer-container__heading {
  color: $primary-color;
  font-size: 23px;
  margin-bottom: 20px;
  font-weight: normal;
}

.footer-container__subheading {
  margin: 0;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: $primary-color;
  display: block;
}

.footer-container__list {
  li {
    margin: 5px 0px;
  }
}

.col--50 {
  flex: 0.5;
  display: flex;
  flex-direction: column;
}

.col--25 {
  flex: 0.25;
}

.socials {
  li {
    font-size: 30px;
    margin: 10px 0;
  }
}

img.logo {
  width: 100px;
  margin-top: auto;
}

.footer-copy {
  background: $extra-dark-color;
  color: rgb(89 105 86);
  padding: 30px 10%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

@media (max-width: $breakpoint-lg) {
  .footer-container {
    flex-direction: column;
  }

  .col:not(:first-child) {
    margin-top: 50px;
  }

  img.logo {
    margin-top: 20px;
  }
}

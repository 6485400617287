@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';
@import url("https://use.typekit.net/txb8mrk.css");

html {
  scroll-behavior: smooth;
}

body {
  font-family: freight-sans-pro, sans-serif;
  margin: 0;
  overflow-x: hidden;
  color: $dark-color;
}

#root {
  overflow-x: hidden;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 42px;
  font-weight: 400;
}

h2 {
  font-size: 30px;
  font-weight: 600;
  font-family: museo-sans, sans-serif;
  color: $dark-color;
  line-height: 1.1;
}

.font--large {
  font-size: 48px;
  font-weight: 100;
}

.font--thin {
  font-family: freight-sans-pro-lights, sans-serif;
}

.margin--none {
  margin: 0;
}

.button-group {
  margin-top: 70px;

  a:nth-child(n+1),
  button:nth-child(n+1) {
    margin-right: 20px;
  }

  & > * {
    margin-top: 20px;
  }
}

.text--center {
  text-align: center;
}

.marginTop {
  margin-top: 150px;
}

.fullWidth {
  width: 100%;
}

.halfWidth {
  width: 50%;
}

.display--flex {
  display: flex;
}

.display--flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.display--block {
  display: block;
}

.col--auto {
  flex: 1;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

a.button,
button {
  padding: 15px 25px;
  font-size: 16px;
  border-radius: 60px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  color: $primary-color;
  border: $primary-color 2px solid;
  min-width: 150px;
  display: inline-block;
  text-align: center;
  font-family: museo-sans, sans-serif;
  transition: all .2s ease-in-out;

  &.button--primary {
    color: white;
    background-color: $primary-color;
  }

  &.button--light {
    color: $primary-color;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
  }

  &.button--small {
    min-width: unset;
    padding: 0 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  &:hover {      
    background-color: $dark-color;
    border-color: $dark-color;
    color: #FFFFFF;
  }
}

button {
  cursor: pointer;
}

.content-bg {
  background: url('./assets/images/background-tex-03.png') no-repeat;
  background-size: 100%;
  background-position: bottom center;

  &--top {
    background-position: top center;
  }
}

.header-small {
  font-size: 28px;
  color: $primary-color;
  font-weight: 600;
}

.text-large {
  font-size: 63px;
  color: $dark-color;
}

.text-small {
  font-size: 24px;
}

.back-button {
  box-shadow: 0px 0px 10px 2px rgba(175, 182, 170, 0.4);
  border-radius: 60px;
  background: #FFFFFF;
  padding: 20px 25px;
  width: fit-content;
  text-transform: uppercase;
  text-decoration: none;
  color: $primary-color;
  margin-bottom: 50px;
  display: flex;

  img {
    width: 20px;
    vertical-align: middle;
    margin-right: 10px;
  }

  &:hover {
    background: $extra-light-color;
    text-decoration: none;
  }
}

/* Media Queries */
@media (max-width: 1600px) {
  .text-large {
    font-size: 42px;
  }

  .font--large {
    font-size: 42px;
    line-height: 1.2;
  }
}

@media (max-width: $breakpoint-xl) {
  .button-group {
    margin-top: 50px;
  }
}

@media (max-width: $breakpoint-md) {
  .font--large {
    font-size: 38px;
  }

  .text-large {
    font-size: 32px;
  }

  section {
    padding: 100px 15px;
  }
}

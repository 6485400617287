@import 'assets/styles/colours.scss';
@import 'assets/styles/sizes.scss';

.categories {
  background: url('../../assets/images/background-tex-02.png') $dark-color;
  background-size: cover;
  background-position: bottom;
  color: $light-color;

  :global(.button):not(:global(.button--primary)) {
    background-color: transparent;
    border-color: $light-color;
    color: $light-color;
  }

  :global(.button):hover {
    border-color: $light-color;
    background-color: $light-color;
    color: $primary-color;
  }
}

  .categories__heading {
    font-weight: 400;
    font-size: 56px;
    margin-bottom: 40px;
    color: $light-color;
  }

  .categories__list {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }

  .categories__list-item {
    position: relative;
    border-radius: 20px;
    margin: 0 4px;
    overflow: hidden;
    width: calc(25% - 22px);
    transition: transform .3s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }

    &::after {
      content: "";
      background: linear-gradient(to bottom, transparent 40%, white);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }

    img {
      width: 100%;
      display: block;
    }

    h3 {
      color: $primary-color;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 30px;
      margin-bottom: 25px;
      z-index: 1;
      font-family: museo-sans, sans-serif;
    }
  }

.mission {
  background-color: $dark-color;
  color: $light-color;
  position: relative;
  padding-top: 0;
  background-image: url('../../assets/images/horizontal-decoration-01.png');
  background-repeat: repeat no-repeat;
  background-position: bottom;
  margin-top: -1px;
  overflow: hidden;

  &__heading {
    margin-top: 0;
    font-family: museo-sans, sans-serif;
    color: $light-color;
  }
  
  &__image {
    position: absolute;
    right: 0;
    width: 45%;
    top: 0;
    z-index: 1;
    border-radius: 30px 0 0 30px;
    max-width: 700px;
  }
}

.wholesales {
  background: $light-color;
  background-image: url('../../assets/images/horizontal-decoration-02.png');
  background-repeat: repeat no-repeat;
  background-position: bottom;
  color: $dark-color;
  padding: 100px 10% 450px 10%;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__container {
    width: 50%;
    display: inline-block;
  }

  &__heading {
    color: $primary-color;
    font-family: museo-sans, sans-serif;
  }

  &__list {
    width: 40%;
    display: inline-block;
    margin-left: 10%;
    color: $dark-color;
  }

  &__list-item {
    padding: 20px;
    font-weight: 400;
    font-size: 40px;
    display: flex;

    &:not(:last-of-type) {
      border-bottom: 1px solid $dark-color;
    }

    img {
      margin-right: 20px;
      width: 50px;
    }
  }
}

.recipes {
  padding: 300px 10% 0 10%;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('../../assets/images/background-tex-01.png') $extra-light-color;
    background-size: cover;
    transform: scale(-1);
    z-index: -1;
  }

  &__banner {
    padding: 100px 0;
    width: 100%;
  }
}

/* Media Queries */
@media (max-width: $breakpoint-xl) {
  .categories__list-item {
    h3 {
      font-size: 25px;
    }
  }

  .wholesales__list-item {
    font-size: 30px;
  }
}

@media (max-width: $breakpoint-lg) {
  .categories__list-item {
    width: calc(25% - 12px);

    h3 {
      font-size: 22px;
    }
  }

  .mission {
    :global(.halfWidth) {
      width: 100%;
    }
  }

    .mission__image {
      position: relative;
      width: 100%;
      right: 0;
      margin-top: 50px;
      border-radius: 30px;
    }

  
  .wholesales__container {
    width: 100%;
  }

  .wholesales__list {
    margin: auto;
    margin-top: 50px;
    width: 80%;
  }

  .wholesales__list-item {
    font-size: 25px;

    img {
      width: 40px;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .categories__list {
    flex-wrap: wrap;
  }

    .categories__list-item {
      width: calc(50% - 12px);
      margin-bottom: 24px;
    }
}

@media (max-width: $breakpoint-sm) {
  .wholesales {
    padding: 100px 10% 480px 10%;
  }

  .recipes {
    padding: 350px 10% 0 10%;
  }

  .categories__list-item {
    width: 100%;
  }
}